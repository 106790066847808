import {renderSkuPicker, setup} from '@contentful/ecommerce-app-base';
import algoliasearch from 'algoliasearch/lite';

const DIALOG_ID = 'root';
const PER_PAGE = 20;

const ALGOLIA_APP_ID = "AX2IXV4HLL";
const SKU_IDENTIFICATION_FIELD = "code";
const OBJECT_ID_FIELD = "objectID";
const OBJECT_ID_PREFIX = "urn:yaas:saasag:caasproduct:product:"
const UNPUBLISHED_TAG = " [UNPUBLISHED]"

const getAlgoliaAppId = (config): string => {
  if (config.applicationId !== undefined && config.applicationId.length > 0) {
    return config.applicationId
  }
  return ALGOLIA_APP_ID
}

const fetchProductPreviews = async (skus, config) => {
    if (!skus.length) {
        return [];
    } else {
        const searchClient = algoliasearch(getAlgoliaAppId(config), config.searchKey);
        const index = searchClient.initIndex(config.indexName)
        const results = await index.search('', {
            facets: [OBJECT_ID_FIELD],
            facetFilters: [skus.map(sku => OBJECT_ID_FIELD + ":" + OBJECT_ID_PREFIX + config.indexName.split('_')[1] + ";" + sku)]
        })
        return results.hits.map(mapHits())
    }
}

async function fetchSKUs(installation, search, pagination) {
    const searchClient = algoliasearch(getAlgoliaAppId(installation), installation.searchKey);
    const index = searchClient.initIndex(installation.indexName)
    const results = await index.search(search, {offset: pagination.offset, length: PER_PAGE})
    return {hits: results.hits, total: results.nbHits}
}

const retrieveName = (hit): string => {
  const isUnpublished = hit._tags.includes("unpublished")
  if (hit.name && hit.name.length > 0) {
    return hit.name[0] + (isUnpublished ? UNPUBLISHED_TAG : "")
  }
  if (hit.localizedName) {
    return (hit.localizedName['en'] ?? "") + (isUnpublished ? UNPUBLISHED_TAG : "")
  }
  return ''
}

const retrieveDescription = (hit): string => {
  if (hit.description && hit.description.length > 0) {
    return hit.description[0]
  }
  if (hit.localizedDescription) {
    return hit.localizedDescription['en'] ?? ''
  }
  return ''
}

function mapHits() {
    return hit => ({
        id: hit[SKU_IDENTIFICATION_FIELD],
        image: hit.image,
        name: retrieveName(hit),
        sku: hit[SKU_IDENTIFICATION_FIELD],
        externalLink: '',
        displaySku: retrieveDescription(hit),
        productId: hit[SKU_IDENTIFICATION_FIELD]
    });
}

setup({
    makeCTA: () => 'Select products',
    name: 'Emporix APP',
    logo: 'https://f.hubspotusercontent30.net/hub/9119985/hubfs/website_resources/logos/emporix_signet_circle.png?width=108&height=108',
    color: '#d7f0fa',
    description: 'Emporix e-commerce app connector. Search for products in your catalog and attach products to your content',
    parameterDefinitions: [
        {
            id: 'searchKey',
            type: 'Symbol',
            name: 'Search Key',
            description: 'Algolia search key',
            required: true,
        }, {
            id: 'indexName',
            type: 'Symbol',
            name: 'Index name',
            description: 'Algolia index name',
            required: true,
        }, {
            id: 'applicationId',
            type: 'Symbol',
            name: 'Application id',
            description: 'Algolia application id. Emporix algolia application id is used when this parameter is not provided.',
            required: false,
        },
    ],
    validateParameters: () => null,
    fetchProductPreviews,
    renderDialog: async (sdk) => {
        const container = document.createElement('div');
        container.id = DIALOG_ID;
        container.style.display = 'flex';
        container.style.flexDirection = 'column';
        document.body.appendChild(container);
        renderSkuPicker(DIALOG_ID, {
            sdk,
            fetchProductPreviews,
            fetchProducts: async (search, pagination) => {
                const {hits, total} = await fetchSKUs(sdk.parameters.installation, search, pagination);
                return {
                    pagination: {
                        count: PER_PAGE,
                        limit: PER_PAGE,
                        total,
                        offset: pagination.offset
                    },
                    products: hits.map(mapHits())
                };
            }
        });

        sdk.window.startAutoResizer();
    },
    openDialog: async (sdk, currentValue, config) => {
        const skus = await sdk.dialogs.openCurrentApp({
            allowHeightOverflow: true,
            position: 'center',
            shouldCloseOnEscapePress: true,
            parameters: config,
            title: "Select products"
        });
        return Array.isArray(skus) ? skus : [];
    },
    isDisabled: () => false,
});
